import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { graphql } from "gatsby"
import { formatSearch } from "../utils/formatSearch"
import { Link } from "gatsby"
import moment from "moment"

class Search extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      searchString: "",
      films: [],
    }
    this.handleChange = this.handleChange.bind(this)
  }

  componentDidMount() {
    const events = formatSearch(this.props.data.allIcal.edges)

    this.setState({
      films: events
    });
    this.refs.search.focus();
  }

  handleChange() {
    this.setState({
      searchString: this.refs.search.value
    });
  }

  render() {
    let _films = this.state.films;
    let search = this.state.searchString.trim().toLowerCase();

    if (search.length > 0) {
        _films = _films.filter(function(user) {
          return user.title.toLowerCase().match(search);
        });
      }

    return (
      <Layout>
        <SEO title="Search" />
        <div>
          <input
            type="text"
            value={this.state.searchString}
            ref="search"
            onChange={this.handleChange}
            placeholder="search by film title"
            aria-label="Search"
            style={{
              width: `100%`,
              margin: `5px auto 30px auto`,
              padding: 10,
              border: `1px solid #ccc`,
              borderRadius: 5,
              textAlign: `center`
            }}
          />
          {_films.map(item => (
            <div style={{ margin: `35px auto`}} key={item.title + item.date}>
              <h4
                style={{
                  fontWeight: `bold`,
                  fontSize: `1.2rem`,
                  marginBottom: 5,
                  color: `#34282C`
                }}
              >
                {item.title}
              </h4>
              <p style={{ marginBottom: 2 }}>
                Playing {moment(item.date).local().format(`dddd, MMMM D, YYYY`)} at {moment(item.date).local().format(`h:mm a`)} at <Link to="/about">{item.location}</Link>
              </p>
           <p style={{ fontStyle: `italic`, color: `dimgray`, fontSize: `0.95rem` }}>
                <a href={item.description} onclick="console.log('adam');" target="_blank" rel="noopener noreferrer">view film information</a>
              </p>
            </div>
          ))}
        </div>
      </Layout>
    )
  }
}

export default Search

export const pageQuery = graphql`
  query {
    allIcal(sort: { fields: start, order: DESC }) {
      edges {
        node {
          summary
          start
          description
          location
          uid
        }
      }
    }
  }
`
