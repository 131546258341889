export function formatSearch(dates) {
  const myDates = []
  var link = ''
  dates.map(({ node }) => {
    // rewrite description here
    if (node.location === "The Cinematheque") {
      link = "http://thecinematheque.ca" + node.description
    } else if (node.location === "Vancity Theatre") {
      link =
        "https://viff.org/Online/default.asp?BOparam::WScontent::loadArticle::permalink=" +
        node.description
    } else if (
      node.location === "Fifth Avenue Cinema" ||
      node.location === "International Village" || 
      node.location === "Scotiabank Theatre"
    ) {
      var cineplexUrlSlug = node.summary
        .replace(/ *\([^)]*\) */g, "")
        .toLowerCase()
        .replace(/ /g, "-")
        .replace(/[^\w-]+/g, "")
      link = "https://www.cineplex.com/Movie/" + cineplexUrlSlug
    } else if (node.location.includes("Rio")) {
      var urlSlug = node.summary
        .replace(/ *\([^)]*\) */g, "")
        .toLowerCase()
        .replace(/ /g, "-")
        .replace(/[^\w-]+/g, "")
      link = "http://riotheatre.ca/movies/" + urlSlug
    }
    myDates.push({
      title: node.summary,
      date: node.start,
      location: node.location,
      description: link,
    })
    return true
  })
  return myDates
}
